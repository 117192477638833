import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Seo from "../seo"
import {products} from "../../properties/products"
import OpenProductPage from "./open-product-page"
import { GridElement } from "../ui/grid/element"
import ContactForm from "../common/contact-form"
import costanti from "../../properties/costanti"

const ProductPage = ( {pageContext} ) => {
    
    const { product } = pageContext

    const _OTHER_PROD_TO_SEE_ = 3

    const id_section_prod = "Scheda-prodotto"
    const id_section_other_prod = "Prodotti-consigliati"
    
    const title_style = "text-4xl text-secondary-600 uppercase font-extrabold font-sans " +
    "group-hover:text-primary-500 group-hover:scale-105 duration-300 text-center md:text-left"
    
    const text_style = "text-secondary-600 mt-14 text-base md:text-lg lg:text-xl group-hover:scale-105 duration-700 " +
    "first-letter:font-sans first-letter:italic first-letter:text-3xl first-letter:text-secondary-500 group-hover:first-letter:text-primary-500 text-center md:text-left "

    const link_style = `relative text-primary-500 dark:hover:text-primary-500
    after:duration-700 after:content-[''] after:absolute after:w-full after:h-0.5
    after:bottom-0 after:bg-primary-500 after:left-0 after:scale-0
    after:hover:scale-100 after:origin-bottom-left`

    // Genera valori "random" da un min ad un massimo compresi
    function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    // Geenra 3 id unici per i prodotti consigliati in fondo alla pagina
    let other_prod_list = []
    for(let i = 0; i< _OTHER_PROD_TO_SEE_; i++){
        let random = randomIntFromInterval(0, (products().list.length - 1 ))

        while((other_prod_list.indexOf(random)>= 0 || products().list[random].title === product.title)){
            random = randomIntFromInterval(0, (products().list.length - 1 ))
        }
        other_prod_list.push( random )
    }

    return(
        <>
            <Seo title={product?.title} description={product?.preview || "Scopri il nostro prodotto!"} />
            
            <OpenProductPage title={product?.title} />

            <section className="mx-5 lg:mx-20 mt-8 lg:mt-14 mb-10" title={id_section_prod} id={id_section_prod}>

                <div
                className={"text-2xl md:text-4xl text-emerald-600 uppercase font-extrabold "+
                "pb-10 pt-10 md:pb-20 md:pt-20 md:hover:pt-32 px-5 " +
                "text-center duration-500 shadow-2xl rounded-3xl -mt-6 md:-mt-16"}>
                    {product?.preview}
                </div>

                <div
                className="group pt-20">
                    <p data-sal="slide-left" data-sal-duration="600"
                    className={title_style}>
                        L'esigenza
                    </p>

                    <p data-sal="slide-right" data-sal-duration="600"
                    className={text_style}>
                        {product?.request}
                    </p>
                </div>

                <div className="group pt-20">
                    <p data-sal="slide-left" data-sal-duration="600"
                    className={title_style}>
                        La soluzione
                    </p>

                    <p data-sal="slide-right" data-sal-duration="600"
                    className={text_style}>
                        {product?.solution}
                    </p>
                </div>

                <div className="group pt-20">
                    <p data-sal="slide-left" data-sal-duration="600"
                    className={title_style}>
                        I Vantaggi
                    </p>

                    <div
                    className={" pl-0 group-hover:pl-5 list-none md:list-disc list-inside duration-500"}>
                    {product?.benefits?.map((pro, index) =>{
                        return(
                            <li key={index} data-sal="zoom-in" data-sal-duration="600"
                            className={text_style + " !mt-5"}>
                                {pro}
                            </li>
                        )
                    })}
                    </div>

                </div>

                <div className="group pt-20">
                    <p data-sal="slide-left" data-sal-duration="600"
                    className={title_style}>
                    Le Tecnologie
                    </p>

                    <div
                    className={"pl-0 md:pl-5 list-none md:list-disc list-inside"}>
                    {product?.tecnologies?.map((tech, index) =>{
                        return(
                            <li data-sal="zoom-in" data-sal-duration="600"
                            key={index} className={text_style + "group-hover:pl-10 duration-500 !mt-5"}>
                                {tech}
                            </li>
                        )
                    })}
                    </div>
                </div>
            </section>

            <ContactForm noTitle text={"Contattaci per maggiori informazioni!"} />

            {
                other_prod_list && 
                <section id={id_section_other_prod} title={id_section_other_prod}>

                    <div data-sal="slide-down" data-sal-duration="600"
                    className="text-center text-4xl text-secondary-600">
                        <span className="pr-2">
                            Dai un'occhiata anche a questi prodotti o
                        </span>
                        
                        <AniLink
                        paintDrip
                        direction="right" 
                        hex={costanti.theme.color.hex}
                        className={link_style}
                        // duration={0.5}
                        to={"/products"}>
                                torna al catalogo
                        </AniLink>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 py-10 md:py-20 md:gap-5 " >
                    {other_prod_list.map((id, index) => {
                        let prodotto = products().list[id]
                        return(
                            <GridElement key={index} title={prodotto.title} 
                            description={prodotto.preview} icon={prodotto.icon}/>
                        )
                    })}
                    </div>
                </section>
            }
        </>
    )
}

export default ProductPage