import React from "react"

const OpenProductPage = ({title, preview}) => {

    return(
        <div className={"w-full flex flex-col justify-center items-center text-justify text-xl overflow-y-hidden"}>
            <div data-sal="fade" data-sal-duration="500"
            className={`w-full flex flex-col items-center justify-center text-secondary-600
            dark:text-secondary-100 bg-[url('/images/products/header-1.jpg')] bg-cover h-[40vh]`}>                        
                <p data-sal="zoom-out" data-sal-duration="800"
                className={`text-5xl md:text-6xl font-sans font-bold
                leading-tight uppercase group-hover:scale-125 text-white`}>
                    {title}
                </p>
                {Boolean(preview) && preview }
            </div>
        </div>            
    )
}

export default OpenProductPage 